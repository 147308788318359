<template>
  <v-row dense>
    <v-col cols="10">
      <!-- Input precios -->
      <template v-if="pricesInput && Object.keys(pricesInput).length > 0">
        <v-row dense>
          <v-col
            v-for="(input, index) in pricesInput"
            :key="index"
            :cols="
              Object.keys(pricesInput).length > 3 && !extraSmallDevice
                ? 12
                : 12 / Object.keys(pricesInput).length
            "
          >
            <v-text-field
              v-model.number="input.price"
              type="number"
              color="grey"
              outlined
              dense
              :suffix="currencySymbol"
              :hide-details="!getErrorsInput(item).length"
              :error-messages="getErrorsInput(item)"
              :label="getRationName(index)"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <span class="text-center caption">Sin precio</span>
      </template>
    </v-col>
    <v-col v-if="buttonEditPrices" cols="2" class="d-flex flex-row align-center">
      <!-- seleccionar precios -->
      <v-btn x-small fab color="primary" :elevation="0" @click="handleClickButtonEditPrices(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { getDefaultRations } from '@/services/ration'
// Utils
import { isNil, get } from 'lodash'

export default {
  name: 'AdvancedManagementProductsDataTableItemPrices',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    buttonEditPrices: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('meta', ['rationsData']),
    ...mapState('app', ['extraSmallDevice']),
    ...mapGetters('place', ['placeData', 'currencyData']),
    /**
     * Currency Symbol
     *
     * @return {String}
     */
    currencySymbol() {
      return get(this.currencyData, 'symbol', null)
    },
    /**
     * Obtiene los precios del producto
     * dependiendo de la categoría
     *
     * @return {Object} - precios del producto
     */
    pricesInput() {
      // Los precios del producto
      return !isNil(this.item.prices) && Object.keys(this.item.prices).length
        ? this.item.prices
        : {}
    }
  },
  watch: {
    'item.prices': {
      handler: function () {
        this.$emit('onItemPricesChanges', this.item)
      },
      deep: true
    }
  },
  methods: {
    /**
     * Obtiene los errores del producto
     *
     * @return {Array} - errores del producto
     */
    getErrorsInput() {
      return get(this.item, 'errors', [])
    },
    /**
     * Obtiene el nombre de la ración
     *
     * @param {String} id - UID tipo de ración
     * @return {String}
     */
    getRationName(id) {
      // Todas la raciones existentes
      const everyRationsData = this.rationsData.reduce((sumRations, ration) => {
        sumRations[ration.id] = {
          name: ration.name
        }
        return sumRations
      }, getDefaultRations())

      return !isNil(id) && everyRationsData[id] ? everyRationsData[id].name : null
    },
    /**
     * Se pulsa sobre el botón de acción
     *
     * @param {Object} - datos del item
     */
    handleClickButtonEditPrices(item) {
      if (item) {
        this.$emit('onClickButtonEditPrices', item)
      }
    }
  }
}
</script>
