<template>
  <div class="advanced-management-price-options">
    <v-container>
      <!-- Price Type -->
      <v-row dense>
        <v-col>
          <v-radio-group v-model="currentType">
            <v-radio
              v-for="type in currentAvailableTypes"
              :key="type.id"
              :label="type.label"
              :value="type.id"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- Price options -->
      <v-sheet
        v-if="currentType === 2 && Array.isArray(priceOptions)"
        :elevation="0"
        color="grey lighten-4"
        rounded
        class="list-price-options"
      >
        <v-row v-for="option in priceOptions" :key="option.id" dense>
          <v-col cols="1" class="d-flex flex-column justify-center align-center">
            <v-checkbox
              v-model="selectedPriceOptions"
              class="mt-0"
              dense
              :value="option.id"
              :hide-details="true"
            />
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="option.name"
              outlined
              dense
              label="Nombre"
              :hide-details="true"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.number="option.price"
              outlined
              dense
              label="Precio por defecto"
              :hide-details="true"
              type="number"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <!-- Add new price option (ration) -->
      <template v-if="currentType === 2">
        <v-row dense>
          <v-col class="text-center mt-2">
            <v-btn text small @click="handleAddRation"> Crear ración </v-btn>
          </v-col>
        </v-row>
      </template>
      <!-- action buttons -->
      <FormButtons
        accept-button-text="Seleccionar"
        :accept-button-loading="processingRequest"
        :cancel-button-hide="true"
        @onClickAcceptButton="handleAcceptButton"
      />
    </v-container>
  </div>
</template>

<script>
// Constants
import { ADDONS } from '@/constants'
// Services
import {
  createRationInPlace,
  updateMetaRationById,
  getDefaultRations,
  getMetaRationByPlaceId
} from '@/services/ration'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { isNil, get } from 'lodash'
import { stringToNumber } from '@/utils'

const uniqid = require('uniqid')

export default {
  name: 'AdvancedManagementPriceOptions',
  components: { FormButtons },
  mixins: [addonsMixin],
  props: {
    // Tipos de precios disponibles
    availableTypes: {
      type: Array,
      default() {
        return [0, 1, 2]
      }
    },
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      currentType: 0,
      types: [
        {
          id: 0,
          label: 'Sin precio'
        },
        {
          id: 1,
          label: 'Precio único'
        },
        {
          id: 2,
          label: 'Por tipo de ración'
        }
      ],
      priceOptions: null,
      processingRequest: false,
      selectedPriceOptions: []
    }
  },
  computed: {
    ...mapGetters('meta', ['rationsData']),
    ...mapGetters('place', ['placeData', 'areThereAdditionalLanguages']),
    /**
     * Tipos de precios disponibles
     *
     * @return {Array}
     */
    currentAvailableTypes() {
      return this.types.filter((type) => {
        return this.availableTypes.indexOf(type.id) > -1
      })
    }
  },
  watch: {
    currentType: {
      immediate: true,
      handler(value) {
        switch (value) {
          case 0: // Sin precio
            this.priceOptions = null
            break
          case 1: // Precio único
            this.priceOptions = this.priceOptionsToObjectFromArray(getDefaultRations())
            break
          case 2: //Por tipo de tapa
            this.priceOptions = this.priceOptionsToObjectFromArray(this.rationsData)
            break
          default:
            this.priceOptions = null
        }
      }
    }
  },
  methods: {
    /**
     * Handle add ration to list
     */
    async handleAddRation() {
      const metaRation = await getMetaRationByPlaceId(this.placeData.id)

      if (metaRation) {
        this.priceOptions.push({
          id: uniqid(),
          name: 'Nombre ración',
          metas: { [metaRation.id]: true },
          order: 0,
          price: null,
          new: true
        })
      }
    },
    /**
     * Event triggered when click on "accept" button
     */
    async handleAcceptButton() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Processing request
      this.processingRequest = true

      // Creamos "raciones" si la opción seleccionada es "2"
      if (this.currentType === 2) {
        // Meta-racion genérica
        const { id, metaFields } = await getMetaRationByPlaceId(this.placeData.id)
        // Salvamos los nuevos precios en BD
        const newRations = await Promise.all(
          this.priceOptions
            .filter((price) => {
              return price.new
            })
            .map(async (price) => {
              delete price.new
              const { ration } = await createRationInPlace(price, this.placeData.id, {
                additionalLanguages: placeConfig.additionalLanguages,
                defaultLanguage: placeConfig.defaultLanguage
              })

              return ration
            })
        )
        // Id de las raciones
        newRations.reduce((sumRationsIds, ration) => {
          sumRationsIds[ration.id] = true
          return sumRationsIds
        }, metaFields)

        // Actualizamos meta-ración padre
        await updateMetaRationById({ id, metaFields })

        // Only pass the selected options
        if (this.selectedPriceOptions.length) {
          this.priceOptions = this.priceOptions.reduce((sumPrices, price) => {
            if (this.selectedPriceOptions.indexOf(price.id) > -1) {
              sumPrices.push({
                id: price.id,
                price: stringToNumber(price.price)
              })
            }
            return sumPrices
          }, [])
        }
      }

      // Este evento es lanzado para todo aquel componente
      // que ha sido incluido bajo el component "CustomDialog"
      this.$emit('onEventComponent', this.priceOptionsToArrayFromObject(this.priceOptions))
      // Reset everything
      this.selectedPriceOptions = []
      this.currentType = 0
      this.priceOptions = null
      // Processing request
      this.processingRequest = false
    },
    /**
     * Parse "priceOptions" from object to array
     *
     * @param {Object} priceOptions - prices (rations) values
     * @return {Array} - object to save in dish model
     */
    priceOptionsToArrayFromObject(priceOptions) {
      if (!isNil(priceOptions)) {
        return priceOptions.reduce((sumPrices, price) => {
          const { id, ...last } = price
          sumPrices[id] = { ...last }

          return sumPrices
        }, {})
      }

      return priceOptions
    },
    /**
     * Parse "priceOptions" from array to object
     *
     * @param {Array} priceOptions - prices (rations) values
     * @return {Object} - object to save in dish model
     */
    priceOptionsToObjectFromArray(priceOptions) {
      if (!isNil(priceOptions)) {
        return Object.entries(priceOptions).reduce((sumPrices, price) => {
          sumPrices.push({
            id: price[0],
            ...price[1]
          })

          return sumPrices
        }, [])
      }

      return priceOptions
    }
  }
}
</script>
<style lang="scss" scoped>
.advanced-management-price-options {
  .list-price-options {
    padding: 0.5rem 1rem;
  }
}
</style>
