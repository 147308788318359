<template>
  <div class="advanced-management-calculator">
    <v-row dense>
      <v-col cols="12" md="3">
        <!-- Cantidad -->
        <v-text-field
          v-model.number="number"
          label="Cantidad"
          type="number"
          outlined
          dense
          color="grey"
          :hide-details="true"
        />
      </v-col>
      <v-col cols="12" md="3">
        <!-- Operación -->
        <v-select
          v-model="operator"
          :items="operatorTypes"
          item-value="value"
          item-text="label"
          label="Operación"
          outlined
          dense
          color="grey"
          :hide-details="true"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <!-- Tipo de cálculo -->
        <v-select
          v-model="calculation"
          :items="calculationTypes"
          item-value="value"
          item-text="label"
          label="Cálculo"
          outlined
          dense
          color="grey"
          :hide-details="true"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn color="primary" elevation="0" block @click="handleClickApplyButton"> Aplicar </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Utils
import { stringToNumber } from '@/utils'

export default {
  name: 'AdvancedManagementCalculator',
  data() {
    return {
      calculation: 'number',
      calculationTypes: [
        {
          value: 'number',
          label: 'Número'
        },
        {
          value: 'percentage',
          label: 'Porcentaje'
        }
      ],
      operator: 'add',
      operatorTypes: [
        {
          value: 'add',
          label: 'Sumar'
        },
        {
          value: 'subtract',
          label: 'Restar'
        }
      ],
      number: null
    }
  },
  methods: {
    /**
     * Evento lanzado tras pulsar sobre el botón de "Aplicar"
     */
    handleClickApplyButton() {
      this.$emit('onClickApplyButton')
    },
    /**
     * Ejecuta la instrucción indicada y emite el cálculo
     *
     * @param {Number} value - valor desde el que comenzamos a calcular
     * @return {Number} - resultado de la operación
     */
    runCalculation(value) {
      let currentValue = stringToNumber(value)
      const currentNumber = stringToNumber(this.number)

      if (Number.isNaN(currentValue) || Number.isNaN(currentNumber)) {
        return value
      }

      if (this.operator === 'add') {
        currentValue =
          this.calculation === 'number'
            ? currentValue + currentNumber
            : currentValue + currentValue * (currentNumber / 100)
      } else if (this.operator === 'subtract') {
        currentValue =
          this.calculation === 'number'
            ? currentValue - currentNumber
            : currentValue - currentValue * (currentNumber / 100)
      }

      return Math.round(currentValue * 100) / 100
    }
  }
}
</script>
