<template>
  <div class="advanced-management-products-data-table">
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      v-bind="vDataTableOptions"
      class="elevation-1"
    >
      <!-- Información cabecera tabla -->
      <template v-if="infoText" v-slot:top>
        <div class="data-table-top">
          <v-alert text dense type="info">
            {{ infoText }}
          </v-alert>
        </div>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <AdvancedManagementProductsDataTableItemPrices
          :item="item"
          :button-edit-prices="buttonEditPrices"
          @onClickButtonEditPrices="handleClickButtonEditPrices"
          @onItemPricesChanges="handleItemPricesChanges"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
// Components
import AdvancedManagementProductsDataTableItemPrices from './AdvancedManagementProductsDataTableItemPrices'

export default {
  name: 'AdvancedManagementProductsDataTable',
  components: {
    AdvancedManagementProductsDataTableItemPrices
  },
  props: {
    headers: {
      type: Array,
      default() {
        return []
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    infoText: {
      type: String,
      default: null
    },
    buttonEditPrices: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedItems: this.items
    }
  },
  computed: {
    /**
     * Obtiene las configuraciones que aplicaremos
     * a la tabla de productos
     *
     * @return {Object} - objeto con opciones
     */
    vDataTableOptions() {
      return {
        'disable-sort': true,
        'disable-pagination': true,
        'hide-default-footer': true,
        'item-key': 'id',
        'loading-text': 'Cargando elementos...',
        'no-data-text': 'Sin productos',
        'show-select': true
      }
    }
  },
  watch: {
    selectedItems: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit('onChangeSelectedItems', value)
      }
    }
  },
  methods: {
    /**
     * Devolvemos hacia el padre, el evento de la
     * edición de los precios de un item
     *
     * @param {Object} item - producto a editar
     */
    handleClickButtonEditPrices(item) {
      this.$emit('onClickButtonEditPrices', item)
    },
    /**
     * Devolvemos hacia el padre, el evento de la
     * edición de los precios de un item
     *
     * @param {string} item - producto a editar
     */
    handleItemPricesChanges(item) {
      this.$emit('onItemPricesChanges', {
        id: `items.$each.${item.index}.prices`,
        item
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.advanced-management-products-data-table {
  height: 100%;
  .vuetify-data-table {
    padding: 0;
  }
  .data-table-top {
    padding: 0.5rem;
    .v-alert {
      margin-bottom: 0;
      font-size: 0.85rem;
      line-height: 1.15rem;
    }
  }
}
</style>
